export default {
  name: 'sqlConnect',
  data() {
    return {
      text: null,
      loading: false,
      sqlData: [],
      sqlFields: [],
      rowCount: 1,
    };
  },
  mounted() {},
  methods: {
    getSqlConnectData() {
      this.sqlFields = [];
      this.sqlData = [];
      const payload = {
        query: this.text,
        rowcount: this.rowCount
      };
      this.loading = true;
      this.$store
        .dispatch('sqlConnect/getSQLConnect', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            for (let i in response.data.data[0]) {
              this.sqlFields.push({
                key: i
              });
            }
            this.sqlData = response.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
